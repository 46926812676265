<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultIndexHeader :routePush="'/RegisterTender'" :titleHeader="'Registrar nueva licitación'" />
            <!-- Table -->
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Listado de Invitaciones Publicas
                                </h5>
                            </div>
                        </div>

                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Codigo</th>
                                    <th class="th-colum-item">Tipo de Proceso</th>
                                    <th class="th-colum-item">Estado</th>
                                    <th class="th-colum-item">Entidad</th>
                                    <th class="th-colum-item">Objeto</th>
                                    <th class="th-colum-item">Cuantía</th>
                                    <th class="th-colum-item">Fecha</th>
                                </tr>
                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in listTenders" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.number_proccess }}</td>
                                        <td class="th-colum-item"> {{ item.type_proccess }} </td>
                                        <td class="th-colum-item"> {{ item.status }} </td>
                                        <td class="th-colum-item"> {{ item.entity }} </td>
                                        <td class="th-colum-item"> {{ item.typeObject }} </td>
                                        <td class="th-colum-item"> {{ item.cuantia }} </td>
                                        <td class="th-colum-item"> {{ item.date }} </td>

                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <router-link class="text-white"
                                            :to="{ name: 'detailTender', params: { _id: item._id } }">
                                            <td class="td-container-action td-success">
                                                <i class="el-icon-success icon-action"></i>
                                            </td>
                                        </router-link>

                                        <router-link class="text-white"
                                            :to="{ name: 'editTender', params: { _id: item._id } }">
                                            <td class="td-container-action td-warning">
                                                <i class="el-icon-edit icon-action"></i>
                                            </td>
                                        </router-link>

                                        <td class="td-container-action td-danger"
                                            @click="showConfirmation(index, item._idName)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>

                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </a-col>
            </a-row>
            <!-- End Table -->
        </div>
    </div>
</template>

<script>

import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader
    },
    data() {
        return {
            listTenders: [],
            isLoading: false,
            dataGetDocs: [],
        }
    },
    mounted() {
        this.getAllTenders()
    },
    methods: {
        async getAllTenders() {
            this.isLoading = true
            await db.collection('tenders')
                .get()
                .then((querySnapshot) => {
                    this.dataGetDocs = querySnapshot.docs;
                    const tenders = [];
                    querySnapshot.forEach((doc) => {
                        tenders.push(doc.data());
                    });
                    this.listTenders = tenders;
                    this.isLoading = false
                })
        },

        showConfirmation(doc_id) {
            this.$swal
                .fire({
                    title: 'Eliminar licitación',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteStorageFolder(doc_id);
                });
        },

        async deleteStorageFolder(childName) {
            this.isLoading = true
            const folderRef = storage.ref().child('pdfsAnexosTender/' + childName);
            try {
                const folderFiles = await folderRef.listAll();
                if (folderFiles.items.length === 0) {
                    await db.collection('tenders')
                        .doc(this.dataGetDocs[childName].id)
                        .delete()
                        .then(() => {
                            this.isLoading = false
                            this.$swal
                                .fire({
                                    title: 'Acción exitosa',
                                    text: 'Licitacion eliminada exitosamente',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'Aceptar',
                                    confirmButtonColor: "#45A825",
                                })
                            return this.getAllTenders()
                        })
                } else await Promise.all(folderFiles.items.map((item) => item.delete()));

            } catch (error) {
                this.isLoading = false
            }
        }
    },
}
</script>

<style>
.new-lic-content2 {
    cursor: pointer;
    float: right;
    display: flex;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--colorGreenPrimary);
}

.btn-new-tender {
    padding: 10px;
    border: 0;
    color: white;
    background-color: transparent;
}
</style>